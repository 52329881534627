import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';

import { refreshTokenFetching } from '../redux/slices/loginSlice';

export const useRefreshToken = () => {
    const dispatch = useDispatch();
    const [cookies] = useCookies();

    const lang = cookies.i18next;

    const refreshTokenDispatch = () => {
        dispatch(
            refreshTokenFetching({
                username: cookies?.username,
                refresh_token: cookies?.refresh_token,
                lang,
            })
        );
    };

    return refreshTokenDispatch;
};
