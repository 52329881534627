import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { isAuth } from '../../redux/slices/loginSlice';
import { HeaderLogout } from '../headerLogout';
import { HeaderUser } from '../headerUser';
import { LanguageSwitcher } from '../languageSwitcher';
import Styles from './styles.module.scss';
import logo from './images/logo.svg';

export const Header = () => {
    const isAuthState = useSelector(isAuth);

    const headerClassName = Styles.header + (isAuthState ? ' ' + Styles.auth : '');

    return (
        <header className={headerClassName}>
            <Link to="/" className={Styles.logo}>
                <img src={logo} alt="Kaizen" />
            </Link>

            {isAuthState && <HeaderUser />}
            <LanguageSwitcher />
            {isAuthState && <HeaderLogout />}
        </header>
    );
};
