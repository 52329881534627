import React, { useEffect, useMemo, useState } from 'react';
import { useTable } from 'react-table';
import ReactTooltip from 'react-tooltip';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useCookies } from 'react-cookie';

import Styles from './styles.module.scss';
import { Loader } from '../loader';
import { useDispatch, useSelector } from 'react-redux';
import { access_token as accessTokenState, lang as langState, token_error as tokenErrorState, username as usernameState } from '../../redux/slices/loginSlice';
import { tasksListFetching, tasksList, taskDetailFetching } from '../../redux/slices/applicationsSlice';
import { t } from 'i18next';

export const ApplicationsTable = ({ filter, filterReady, setFilterReady }) => {
    const dispatch = useDispatch();
    const [tasks, setTasks] = useState([]);
    const [page, setPage] = useState(1);
    const [cookies, setCookie] = useCookies();

    const access_token = useSelector(accessTokenState);
    const username = useSelector(usernameState);
    const token_error = useSelector(tokenErrorState);
    const tasks_list = useSelector(tasksList);
    const lang = useSelector(langState);

    useEffect(() => {
        (async () => {
            if (filterReady && lang) {
                if (filter) {
                    setPage(1);
                    await dispatch(tasksListFetching({ username, access_token, page: 1, filter, lang }));
                } else {
                    await dispatch(tasksListFetching({ username, access_token, page, filter, lang }));
                }
                setFilterReady(false);
            }
        })();
    }, [filterReady, token_error, lang]);

    useEffect(() => {
        if (tasks_list && tasks_list?.tasks) {
            if (page === 1) {
                setTasks([...tasks_list.tasks]);
            } else {
                setTasks([...tasks, ...tasks_list.tasks]);
            }
        }
    }, [tasks_list]);

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    const fetchMoreData = () => {
        dispatch(tasksListFetching({ username, access_token, page: page + 1, filter, lang }));
        setPage(page + 1);
    };

    const handlerShowPanel = task_id => {
        dispatch(taskDetailFetching({ username, access_token, task_id, lang }));
    };

    const data = useMemo(() => {
        return tasks.map(task => ({
            ...task,
            user_name: task.user_id ? task.user_name : t('NOT_SET'),
        }));
    }, [tasks, cookies.i18next]);

    const columns = useMemo(
        () => [
            {
                Header: t('APPLICATIONS_STATUS'),
                accessor: 'status_name',
            },
            {
                Header: t('APPLICATIONS_ID'),
                accessor: 'id',
            },
            {
                Header: t('APPLICATIONS_OBJ'),
                accessor: 'venue_name',
            },
            {
                Header: t('APPLICATIONS_DESCRIPTION'),
                accessor: 'description',
            },
            {
                Header: t('APPLICATIONS_EXECUTOR'),
                accessor: 'user_name',
            },
            {
                Header: t('APPLICATIONS_DATE_OF_CREATION'),
                accessor: 'start_date',
            },
            {
                Header: t('APPLICATIONS_COMPLETE_BEFORE'),
                accessor: 'expected_end_date',
            },
        ],
        [cookies.i18next]
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

    if (tasks_list && tasks_list.total_count === 0) {
        return <p>{t('NOT_FOUND')}</p>;
    }

    if (!data.length) {
        return <Loader />;
    }

    return (
        <>
            <section className={Styles.tableWrapper}>
                <InfiniteScroll dataLength={rows.length} next={fetchMoreData} hasMore={tasks_list?.count && tasks_list?.total_count > 20 ? true : false} loader={<h4>{t('LOADING')}...</h4>}>
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                // console.log('row', row.original);

                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} onClick={() => handlerShowPanel(row.original.id)}>
                                        {row.cells.map(cell => {
                                            // console.log('cell', cell);

                                            const params = {};
                                            let repeated,
                                                overdue,
                                                department_name = null;

                                            //tooltip для описания
                                            if (cell.column.id === 'description') {
                                                params['data-tip'] = cell.value;
                                            }

                                            //цвета для статуса
                                            if (cell.column.id === 'status_name') {
                                                params.className = Styles[`status_${row.original.status_id}`];
                                                if (row.original.repeated) {
                                                    repeated = <div>{t('APPLICATIONS_REPEATED')}</div>;
                                                }
                                                if (row.original.overdue) {
                                                    overdue = <div className={Styles.overdue}>{t('APPLICATIONS_OVERDUE')}</div>;
                                                }
                                            }

                                            //цвета для дат
                                            if (cell.column.id === 'start_date' || cell.column.id === 'expected_end_date') {
                                                if (cell?.value) {
                                                    const dateArr = cell.value.split(' ');

                                                    return (
                                                        <td className={Styles[cell.column.id]} {...cell.getCellProps()}>
                                                            <div {...params}>
                                                                {dateArr[0]} <span>{dateArr[1]}</span>
                                                            </div>
                                                        </td>
                                                    );
                                                }
                                            }

                                            //название департамента для объекта
                                            if (cell.column.id === 'venue_name') {
                                                if (row.original.department_name && row.original.category_name) {
                                                    department_name = (
                                                        <div>
                                                            {row.original.department_name} / {row.original.category_name}
                                                        </div>
                                                    );
                                                }
                                            }

                                            return (
                                                <td className={Styles[cell.column.id]} {...cell.getCellProps()}>
                                                    <div {...params}>
                                                        {cell.render('Cell')}
                                                        {repeated ? repeated : ''}
                                                        {overdue ? overdue : ''}
                                                        {department_name ? department_name : ''}
                                                    </div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </InfiniteScroll>
            </section>
            <ReactTooltip className={Styles.tooltip} />
        </>
    );
};
