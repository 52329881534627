import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './slices/loginSlice';
import applicationsReducer from './slices/applicationsSlice';
import usersReducer from './slices/usersSlice';

export const store = configureStore({
    reducer: {
        login: loginReducer,
        applications: applicationsReducer,
        users: usersReducer,
    },
});
