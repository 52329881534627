import React from 'react';

import Styles from './styles.module.scss';
import closeIcon from './images/close-circle.svg';
import exclamationIcon from './images/exclamation-circle.svg';
import infoIcon from './images/info-circle.svg';

export const LoginNotifications = ({ error }) => {
    let img;
    switch (error[0]) {
        case 'info':
            img = infoIcon;
            break;
        case 'error':
            img = closeIcon;
            break;
        case 'other':
            img = exclamationIcon;
            break;
        default:
            img = infoIcon;
    }

    return (
        <div className={Styles.notificationBlock}>
            <div className={Styles.wrapper}>
                <div className={Styles.img}>
                    <img src={img} alt="icon" />
                </div>
                <div className={Styles.text}>{error[1]}</div>
            </div>
        </div>
    );
};
