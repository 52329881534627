import React from 'react';
import { useCookies } from 'react-cookie';
import { t } from 'i18next';

import userIcon from './images/user.svg';
import Styles from './styles.module.scss';

export const HeaderUser = () => {
    const [cookies] = useCookies();

    return (
        <div className={Styles.user}>
            <img src={userIcon} alt="user" />
            {cookies?.name ? cookies.name : t('NOT_SET')}
        </div>
    );
};
