import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import Styles from './styles.module.scss';
import reports from './images/files.svg';
import users from './images/users.svg';
import applications from './images/check_all.svg';
import { book } from '../../navigation/book';
import { t } from 'i18next';

export const LeftColumn = () => {
    const location = useLocation();

    return (
        <section className={Styles.section}>
            <nav>
                <Link to={book.applications.path} className={location.pathname.includes(book.applications.path) ? Styles.active : ''}>
                    <img src={applications} alt="icon" />
                    {t('APPLICATIONS')}
                </Link>
                <Link to={book.reports.path} className={location.pathname.includes(book.reports.path) ? Styles.active : ''}>
                    <img src={reports} alt="icon" />
                    {t('REPORTS')}
                </Link>
                <Link to={book.users.path} className={location.pathname.includes(book.users.path) ? Styles.active : ''}>
                    <img src={users} alt="icon" />
                    {t('USERS')}
                </Link>
            </nav>
        </section>
    );
};
