import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Styles from './styles.module.scss';
import { ApplicationsFilter } from '../../components/applicationsFilter';
import { ApplicationsTable } from '../../components/applicationsTable';
import { ApplicationDetail } from '../../components/applicationDetail';
import { task } from '../../redux/slices/applicationsSlice';

export const ApplicationsPage = () => {
    const [filter, setFilter] = useState(null);
    const taskState = useSelector(task);

    const [filterReady, setFilterReady] = useState(false);

    return (
        <>
            <section className={Styles.wrapper}>
                <ApplicationsFilter filter={filter} setFilter={setFilter} setFilterReady={setFilterReady} />

                <ApplicationsTable filter={filter} filterReady={filterReady} setFilterReady={setFilterReady} />
            </section>

            <ApplicationDetail task={taskState} />
        </>
    );
};
