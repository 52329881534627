import React from 'react';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';

import { logoutAll } from '../../redux/slices/loginSlice';
import exitIcon from './images/exit_to_app.svg';
import Styles from './styles.module.scss';

export const HeaderLogout = () => {
    const dispatch = useDispatch();

    const [cookies, removeCookie] = useCookies();

    const clickHandler = () => {
        dispatch(logoutAll());
        removeCookie('access_token');
        removeCookie('refresh_token');
        removeCookie('username');
        removeCookie('name');
    };

    return (
        <button className={Styles.button} onClick={clickHandler}>
            <img src={exitIcon} alt="exit" />
        </button>
    );
};
