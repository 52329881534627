import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api';
import { clear_all_users } from './usersSlice';
import { t } from 'i18next';

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        isAuth: false,
        loading: true,
        access_token: '',
        refresh_token: '',
        username: '',
        name: '',
        token_error: false,
        lang: '',
    },
    reducers: {
        login: (state, action) => {
            state.isAuth = true;
            state.access_token = action.payload.access_token;
            state.refresh_token = action.payload.refresh_token;
            state.username = action.payload.email || action.payload.username;
            state.name = action.payload.name;
            state.loading = false;
            state.token_error = false;
        },
        logout: state => {
            state.isAuth = false;
            state.access_token = '';
            state.refresh_token = '';
            state.username = '';
            state.name = '';
            state.loading = false;
            state.token_error = false;
            state.lang = '';
        },
        setAccessToken: (state, action) => {
            state.access_token = action.payload;
            state.token_error = false;
        },
        setTokenError: state => {
            state.token_error = true;
        },
        change_lang: (state, { payload }) => {
            state.lang = payload;
        },
    },
});

export const { login, logout, setAccessToken, setTokenError, change_lang } = loginSlice.actions;

export const logoutAll = () => dispatch => {
    dispatch(logout());
    dispatch(clear_all_users());
};

export const loginFetching =
    ({ username, password, setError, afterAuth, lang }) =>
    async dispatch => {
        try {
            if (username && password) {
                const response = await api.login.fetch(username, password, lang);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.data.success) {
                        dispatch(login(res.data.userInfo));
                        setError([]);
                        afterAuth(res.data.userInfo);
                    } else {
                        setError(['error', t('NOTIF_ERORR_LOGIN')]);
                    }

                    await dispatch(change_lang(res.data.userInfo.lang));
                }
            } else {
                setError(['info', t('NOTIF_EMPTI_FILDS')]);
            }
        } catch (e) {
            console.log('loginFetching error: ', e);
            setError(['other', e]);
        }
    };

export const checkAuth = props => async dispatch => {
    try {
        if (props.username && props.access_token) {
            const response = await api.checkAuth.fetch(props.username, props.access_token, props.i18next);

            if (response.status === 200) {
                const res = await response.json();
                if (res.data?.success) {
                    dispatch(login(props));
                } else {
                    dispatch(logoutAll());
                }
            } else {
                dispatch(logoutAll());
            }
        } else {
            dispatch(logoutAll());
        }
    } catch (e) {
        console.log('checkAuth error: ', e);
        dispatch(logoutAll());
    }
};

export const refreshTokenFetching =
    ({ username, refresh_token, lang }) =>
    async dispatch => {
        try {
            if (username && refresh_token) {
                const response = await api.refreshToken.fetch(username, refresh_token, lang);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 64) {
                        dispatch(logoutAll());
                    } else {
                        if (res.data && res.data.access_token) {
                            dispatch(setAccessToken(res.data.access_token));
                        }
                    }
                }
            } else {
                dispatch(logoutAll());
            }
        } catch (e) {
            console.log('refreshToken error: ', e);
            dispatch(logoutAll());
        }
    };

export const setLanguage = (lang, access_token, username) => async dispatch => {
    try {
        if (lang) {
            const response = await api.setLanguage.fetch(access_token, lang, username);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 58) {
                    console.log('setLanguage', res.msg);
                }

                await dispatch(change_lang(lang));
            }
        }
    } catch (e) {
        console.log('setLanguage error: ', e);
    }
};

export const getLanguage = (username, access_token) => async dispatch => {
    try {
        if (access_token) {
            const response = await api.getLanguage.fetch(username, access_token);
            if (response.status === 200) {
                const res = await response.json();
                if (res.code === 58) {
                    console.log('getLanguage:', res.status);
                }

                await dispatch(change_lang(res.data.lang));
            }
        } else {
            console.log('невалидный access_token ');
        }
    } catch (e) {
        console.log('getLanguage error: ', e);
    }
};

export const isAuth = state => state.login.isAuth;
export const access_token = state => state.login.access_token;
export const refresh_token = state => state.login.refresh_token;
export const username = state => state.login.username;
export const name = state => state.login.name;
export const loading = state => state.login.loading;
export const token_error = state => state.login.token_error;
export const lang = state => state.login.lang;

export default loginSlice.reducer;
