import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTable } from 'react-table';
import { useCookies } from 'react-cookie';

import Styles from './styles.module.scss';
import editIcon from './images/edit.svg';
import { filter as filterState, userGetFetching, usersList as usersListState, usersListFetching } from '../../redux/slices/usersSlice';
import { access_token as accessTokenState, token_error as tokenErrorState, username as usernameState } from '../../redux/slices/loginSlice';
import { t } from 'i18next';

export const UsersTable = ({ setUserOpen }) => {
    const dispatch = useDispatch();
    const menuRef = useRef(null);
    const access_token = useSelector(accessTokenState);
    const username = useSelector(usernameState);
    const usersList = useSelector(usersListState);
    const filter = useSelector(filterState);
    const token_error = useSelector(tokenErrorState);
    const [editBlockIsOpen, setEditBlockIsOpen] = useState(null);
    const [listening, setListening] = useState(false);
    const [cookies, setCookie] = useCookies();

    useEffect(() => {
        (async () => {
            await dispatch(usersListFetching(cookies, { username, access_token, filter }));
        })();
    }, [filter, token_error, cookies.i18next]);

    const listenForOutsideClick = (listening, setListening, menuRef, setIsOpen) => {
        return () => {
            if (listening) return;
            setListening(true);

            document.addEventListener(`click`, evt => {
                const cur = menuRef.current;
                if (!menuRef.current) return;

                const node = evt.target;
                if (cur.contains(node)) return;
                setIsOpen(false);
            });
        };
    };

    useEffect(listenForOutsideClick(listening, setListening, menuRef, setEditBlockIsOpen));

    const userShowHandler = async ({ user_id }) => {
        await dispatch(userGetFetching(cookies, { username, access_token, user_id }));
        setUserOpen(true);
    };

    const data = useMemo(
        () =>
            usersList.map(user => ({
                ...user,
                contractor_name: user.contractor_name ? user.contractor_name : `${user?.last_name} ${user?.first_name} ${user?.middle_name}`,
            })),
        [usersList]
    );

    const columns = useMemo(() => {
        const arr = [
            {
                Header: t('USERS_FULL_NAME'),
                accessor: 'contractor_name',
            },
            {
                Header: t('USERS_TEL'),
                accessor: 'phone',
            },
        ];

        if (filter.userType !== 'customers') {
            arr.push({ Header: t('USERS_STATUS'), accessor: 'status' });
            arr.push({ Header: '', accessor: 'id' });
        }

        return arr;
    }, [usersList, cookies.i18next]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

    if (data && data.length === 0) {
        return <p>{t('NOT_FOUND')}</p>;
    }

    return (
        <section className={Styles.tableWrapper}>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    let additionally = '';

                                    //status
                                    if (cell.column.id === 'status') {
                                        if (cell.value === 10) {
                                            additionally = <div className={Styles.statusInner + ' ' + Styles.active}>{t('USERS_USER_STATUS_ACTIVE')}</div>;
                                        } else if (cell.value === 0) {
                                            additionally = <div className={Styles.statusInner + ' ' + Styles.inactive}>{t('USERS_USER_STATUS_NOT_ACTIVE')}</div>;
                                        } else {
                                            additionally = <div>{t('USERS_USER_STATUS_UNDEFINED')}</div>;
                                        }
                                    }

                                    //edit
                                    if (cell.column.id === 'id') {
                                        additionally = (
                                            <div className={Styles.editBlock} ref={editBlockIsOpen === cell.value ? menuRef : null}>
                                                <button type="button" onClick={() => setEditBlockIsOpen(cell.value)}>
                                                    <img src={editIcon} alt="Изменить" />
                                                </button>
                                                <div className={`${Styles.editTooltip} ${editBlockIsOpen === cell.value ? Styles.showed : ''}`}>
                                                    <button type="button" onClick={() => userShowHandler({ user_id: cell.value })}>
                                                        {t('USERS_EDIT_BTN')}
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    }

                                    return (
                                        <td className={Styles[cell.column.id]} {...cell.getCellProps()}>
                                            {additionally ? additionally : cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </section>
    );
};
