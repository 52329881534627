import React from 'react';
import { useLocation } from 'react-router-dom';
import { book } from '../../navigation/book';
import { t } from 'i18next';

import Styles from './styles.module.scss';

export const TitleRow = () => {
    const location = useLocation();

    const title = Object.values(book).find(obj => obj.path === location.pathname)?.key || '';

    return (
        <section className={Styles.section}>
            <nav>
                <span>{t('MAIN_PAGE')}</span> <span>/</span> <span>{t(`${title}`)}</span>
            </nav>
            <h1>{t(`${title}`)}</h1>
        </section>
    );
};
