import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import Styles from './styles.module.scss';
import { LoginNotifications } from '../../components/loginNotifications';
import { loginFetching } from '../../redux/slices/loginSlice';
import i18n from '../../i18n';

export const LoginPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const fromPage = location.state?.from?.pathname || '/';

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState([]);

    const [cookies, setCookie] = useCookies();
    const dispatch = useDispatch();

    const afterAuth = ({ access_token, email, name, refresh_token }) => {
        setCookie('access_token', access_token);
        setCookie('refresh_token', refresh_token);
        setCookie('username', email);
        setCookie('name', name);

        navigate(fromPage, { replace: true });
    };

    const { t } = useTranslation();
    const lang = cookies.i18next;

    const handlerSubmit = async () => {
        await dispatch(loginFetching({ username, password, setError, afterAuth, lang }));
    };

    return (
        <div className={Styles.wrapper}>
            <div className={Styles.content}>
                <h2>{t('LOGIN')}</h2>
                <input type="text" onChange={e => setUsername(e.target.value)} value={username} placeholder={t('LOGIN_NAME_PL')} />
                <input type="password" onChange={e => setPassword(e.target.value)} value={password} placeholder={t('LOGIN_PASS_PL')} />
                <button type="button" className="btn" onClick={handlerSubmit}>
                    {t('LOGIN_BTN')}
                </button>
                {error.length ? <LoginNotifications error={error} /> : ''}
            </div>
        </div>
    );
};
