import React, { useEffect, useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Styles from './styles.module.scss';
import closeIcon from './images/close-square.svg';
import { useDispatch } from 'react-redux';
import { task_fetching } from '../../redux/slices/applicationsSlice';
import { t } from 'i18next';
import { Fancybox } from '../../hoc/Fancybox';

export const ApplicationDetail = ({ task }) => {
    const [showPanel, setShowPanel] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (task && task.id) {
            setShowPanel(true);
        }
    }, [task]);

    const closeHandler = () => {
        setShowPanel(false);
        dispatch(task_fetching(null));
    };

    return (
        <SlidingPane overlayClassName={Styles.overlay} className={Styles.panel} isOpen={showPanel} hideHeader={true} width="350px" onRequestClose={closeHandler}>
            <div className={Styles.header}>
                <div className={Styles.title}>
                    {t('APPLICATIONS_REQUEST')} ID {task?.id || ''}
                </div>
                <button className={Styles.closeIcon} onClick={closeHandler}>
                    <img src={closeIcon} alt="close" />
                </button>
            </div>

            <div className={Styles.content}>
                <form className={Styles.form}>
                    <label>
                        <p>{t('APPLICATIONS_OBJ')}</p>
                        <input type="text" disabled value={task?.venue || ''} />
                    </label>
                    <label>
                        <p>{t('APPLICATIONS_STATUS')}</p>
                        <input type="text" disabled value={task?.status_name || ''} />
                    </label>
                    <label>
                        <p>{t('APPLICATIONS_SOURCE')}</p>
                        <input type="text" disabled value={task?.category_name || ''} />
                    </label>
                    <label>
                        <p>{t('APPLICATIONS_DEPATRMENT')}</p>
                        <input type="text" disabled value={task?.department_name || ''} />
                    </label>
                    <label>
                        <p>{t('APPLICATIONS_DESCRIPTION')}</p>
                        <textarea disabled value={task?.description || ''} />
                    </label>
                    <label>
                        <p>{t('APPLICATIONS_CREATOR')}</p>
                        <input type="text" disabled value={task?.creator_name || ''} />
                    </label>
                    <label>
                        <p>{t('APPLICATIONS_EXECUTOR')}</p>
                        <input type="text" disabled value={task?.user_name || ''} />
                    </label>
                    <label className={Styles['width-50']}>
                        <p>{t('APPLICATIONS_DATE_OF_CREATION')}</p>
                        <input type="text" disabled value={task?.start_date || ''} />
                    </label>
                    <label className={Styles['width-50']}>
                        <p>{t('APPLICATIONS_COMPLETE_BEFORE')}</p>
                        <input type="text" disabled value={task?.expected_end_date || ''} />
                    </label>

                    <div className={Styles.picsList}>
                        <Fancybox options={{ infinite: false }}>
                            {task &&
                                task.attached_images.length > 0 &&
                                task.attached_images.map((image, index) => (
                                    <div className={Styles.pic} data-fancybox="gallery" data-src={image} key={`image${index}`}>
                                        <img src={image} alt="" />
                                    </div>
                                ))}
                        </Fancybox>

                        {task &&
                            task.attached_files.length > 0 &&
                            task.attached_files.map((file, index) => (
                                <a href={file} target="_blank" className={Styles.pic} key={`file${index}`}>
                                    <svg width="40" height="40" fill="#757575" viewBox="0 0 512 512">
                                        <path d="M459.36,100.64l-96-96C360.341,1.645,356.253-0.024,352,0H96c-26.51,0-48,21.49-48,48v416c0,26.51,21.49,48,48,48h320     c26.51,0,48-21.49,48-48V112C464.025,107.747,462.355,103.66,459.36,100.64z M432,464c0,8.837-7.163,16-16,16H96     c-8.837,0-16-7.163-16-16V48c0-8.837,7.163-16,16-16h240v64c0,17.673,14.327,32,32,32h64V464z" />
                                        <rect x="368" y="384" width="32" height="32" />
                                        <rect x="112" y="224" width="288" height="32" />
                                        <rect x="112" y="304" width="288" height="32" />
                                        <rect x="112" y="384" width="224" height="32" />
                                    </svg>
                                </a>
                            ))}
                    </div>
                </form>
            </div>
        </SlidingPane>
    );
};
