// import { username } from '../redux/slices/loginSlice';
import { root } from './config';

const timeInMs = Date.now();
const app_id = 0;
const gui = 'f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b';

export const api = Object.freeze({
    // установка выбранного языка
    setLanguage: {
        fetch: (access_token, lang, username) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/api/set-lang&app_id=${app_id}&timestamp=${timeInMs}&gui=${gui}&user=${username}`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    // получение языка пользователя из БД

    getLanguage: {
        fetch: (username, access_token) => {
            const formData = new FormData();
            formData.append('access_token', access_token);

            return fetch(`${root}?r=api/api/myinfo&app_id=${app_id}&timestamp=${timeInMs}&gui=${gui}&user=${username}`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // авторизация
    login: {
        fetch: (username, password, lang) => {
            const formData = new FormData();
            formData.append('LoginForm[username]', username);
            formData.append('LoginForm[password]', password);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/api/login&app_id=${app_id}&timestamp=${timeInMs}&gui=${gui}&user=${username}`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    //проверка авторизации
    checkAuth: {
        fetch: (username, access_token, lang) => {
            if (!username && !access_token) {
                return false;
            }

            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/api/verify-auth-access&app_id=${app_id}&timestamp=${timeInMs}&gui=${gui}&user=${username}`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    //получить новый access токен
    refreshToken: {
        fetch: (username, refresh_token, lang) => {
            if (!username && !refresh_token) {
                return false;
            }

            const formData = new FormData();
            formData.append('refresh_token', refresh_token);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/api/refresh-auth-access&app_id=${app_id}&timestamp=${timeInMs}&gui=${gui}&user=${username}`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    //получение списка задач
    tasksListFetching: {
        fetch: (username, access_token, page = 1, filter, lang) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('page', page);
            formData.append('lang', lang);

            // console.log('filter for tasks fetch', filter);

            for (const item in filter) {
                if (filter[item]?.value) {
                    if (item === 'status') {
                        formData.append('TaskSearch[status_list][]', filter[item].value);
                    }
                    if (item === 'sign') {
                        formData.append('TaskSearch[sign_list][]', filter[item].value);
                    }
                    if (item === 'category') {
                        formData.append('TaskSearch[category_list][]', filter[item].value);
                    }
                    if (item === 'department') {
                        formData.append('TaskSearch[department_list][]', filter[item].value);
                    }
                    if (item === 'priority') {
                        formData.append('TaskSearch[priority_list][]', filter[item].value);
                    }
                }

                if (item === 'search' && filter[item].length >= 3) {
                    formData.append('TaskSearch[id_desc]', filter[item]);
                }
            }

            return fetch(`${root}?r=api/api/get-task-list&app_id=${app_id}&timestamp=${timeInMs}&gui=${gui}&user=${username}`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    //получение детальной задачи
    taskDetailFetching: {
        fetch: (username, access_token, task_id, lang) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('task_id', task_id);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/api/get-task-detail&app_id=${app_id}&timestamp=${timeInMs}&gui=${gui}&user=${username}`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    //получение списка признаков заявок
    signListFetching: {
        fetch: (username, access_token, lang) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/api/get-sign-list&app_id=${app_id}&timestamp=${timeInMs}&gui=${gui}&user=${username}`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    //получение списка источников заявок
    categoryListFetching: {
        fetch: (username, access_token, lang) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/api/get-category-list&app_id=${app_id}&timestamp=${timeInMs}&gui=${gui}&user=${username}`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    //получение списка департаментов
    departmentListFetching: {
        fetch: (username, access_token, lang) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/api/get-department-list&app_id=${app_id}&timestamp=${timeInMs}&gui=${gui}&user=${username}`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    //получение списка приоритетов
    priorityListFetching: {
        fetch: (username, access_token, lang) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/api/get-priority-list&app_id=${app_id}&timestamp=${timeInMs}&gui=${gui}&user=${username}`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //получение списка пользователей
    usersListFetching: {
        fetch: (username, access_token, filter, lang) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('lang', lang);

            if (filter?.userType) {
                if (filter.userType === 'active') {
                    formData.append('filter_active', '1');
                } else if (filter.userType === 'inactive') {
                    formData.append('filter_active', '0');
                } else if (filter.userType === 'customers') {
                    formData.append('filter_customer', '1');
                }
            }

            if (filter?.search) {
                formData.append('filter_name_phone', filter.search);
            }

            return fetch(`${root}?r=api/api/get-contractors-users&app_id=${app_id}&timestamp=${timeInMs}&gui=${gui}&user=${username}`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //создание нового пользователя
    userAdd: {
        fetch: (username, access_token, data, lang) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('lang', lang);

            // console.log('data', data);

            if (data) {
                if (data.name) {
                    formData.append('contractor_name', data.name);
                }
                if (data.phone) {
                    formData.append('phone', data.phone);
                }
                if (data.status) {
                    formData.append('active', data.status);
                }
                if (data.password) {
                    formData.append('password', data.password);
                }
            }

            return fetch(`${root}?r=api/api/add-contractors-user&app_id=${app_id}&timestamp=${timeInMs}&gui=${gui}&user=${username}`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    //обновление пользователя
    userUpdate: {
        fetch: (username, access_token, data, lang) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('lang', lang);

            if (data) {
                if (data.name) {
                    formData.append('contractor_name', data.name);
                }
                if (data.phone) {
                    formData.append('phone', data.phone);
                }
                if (data.status) {
                    formData.append('active', data.status);
                }
                if (data.password) {
                    formData.append('password', data.password);
                }
                if (data.id) {
                    formData.append('id', data.id);
                }
            }

            return fetch(`${root}?r=api/api/update-contractors-user&app_id=${app_id}&timestamp=${timeInMs}&gui=${gui}&user=${username}`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //получение пользователя
    userFetching: {
        fetch: (username, access_token, user_id, lang) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('user_id', user_id);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/api/get-contractors-user&app_id=${app_id}&timestamp=${timeInMs}&gui=${gui}&user=${username}`, {
                method: 'POST',
                body: formData,
            });
        },
    },
});
