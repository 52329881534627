import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Styles from './styles.module.scss';
import searchIcon from './images/search-icon.svg';
import { filter as filterState, set_filter } from '../../redux/slices/usersSlice';
import { t } from 'i18next';

export const UsersFilter = ({ setUserOpen }) => {
    const dispatch = useDispatch();
    const filter = useSelector(filterState);
    const [searchLine, setSearchLine] = useState(filter.search);

    const userTypeHandlerClick = usertype => {
        if (usertype !== filter.userType) {
            dispatch(set_filter({ ...filter, userType: usertype }));
        }
    };

    const buttonArr = [
        {
            name: 'employees',
            text: t('USERS_EMPLOYEES'),
        },
        {
            name: 'customers',
            text: t('USERS_CUSTOMERS'),
        },
        {
            name: 'active',
            text: t('USERS_ACTIVE'),
        },
        {
            name: 'inactive',
            text: t('USERS_DISMISSED'),
        },
    ];

    const buttonJSX = buttonArr.map(item => (
        <button name={item.name} type="button" onClick={() => userTypeHandlerClick(item.name)} className={filter.userType === item.name ? Styles.active : ''} key={`button${item.name}`}>
            {item.text}
        </button>
    ));

    //search line
    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (searchLine !== filter.search) {
                dispatch(set_filter({ ...filter, search: searchLine }));
            }
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchLine]);

    return (
        <div className={Styles.wrapper}>
            <div className={Styles.inputTextWrapper}>
                <input type="text" placeholder={t('USERS_INFO')} onChange={e => setSearchLine(e.target.value)} value={searchLine} />
                <img src={searchIcon} alt="search" />
            </div>

            <div className={Styles.filterBlock}>{buttonJSX}</div>

            <div className={Styles.createBlock}>
                <button type="button" className={Styles.btn} onClick={() => setUserOpen(true)}>
                    {t('USERS_CREATE_USER')}
                </button>
            </div>
        </div>
    );
};
