import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api';
import { setTokenError } from './loginSlice';

export const usersSlice = createSlice({
    name: 'users',
    initialState: {
        users_list: [],
        user: null,
        loading: false,
        error: false,
        filter: {
            userType: 'employees',
            search: '',
        },
    },
    reducers: {
        users_list_fetching: (state, action) => {
            state.users_list = action.payload;
        },
        user_fetching: (state, action) => {
            state.user = action.payload;
        },
        fetching_start: state => {
            state.loading = true;
        },
        fetching_end: state => {
            state.loading = false;
        },
        set_filter: (state, action) => {
            state.filter = action.payload;
        },
        clear_filter: state => {
            state.filter = {
                userType: 'employees',
                search: '',
            };
        },
        set_error: (state, action) => {
            state.error = action.payload;
        },
        clear_all_users: state => {
            state.user = null;
            state.loading = false;
            state.error = false;
            state.users_list = [];
            state.filter = {};
        },
    },
});

export const { users_list_fetching, user_fetching, fetching_start, fetching_end, set_filter, clear_filter, set_error, clear_all_users } = usersSlice.actions;

export const usersListFetching =
    (props, { username, access_token, filter }) =>
    async (dispatch, getState) => {
        const token_error = getState().login.token_error;

        if (!token_error) {
            // console.log('usersListFetching');

            dispatch(fetching_start());
            try {
                const response = await api.usersListFetching.fetch(username, access_token, filter, props.i18next);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 64) {
                        dispatch(setTokenError());
                    } else {
                        if (res.data) {
                            dispatch(users_list_fetching(res.data));
                        }
                    }
                }
            } catch (e) {
                console.log('usersListFetching error: ', e);
            } finally {
                dispatch(fetching_end());
            }
        }
    };

export const userAddFetching =
    (props, { username, access_token, data }) =>
    async (dispatch, getState) => {
        const token_error = getState().login.token_error;

        if (!token_error) {
            console.log('userAddFetching');

            dispatch(fetching_start());
            try {
                const response = await api.userAdd.fetch(username, access_token, data, props.i18next);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 64) {
                        dispatch(setTokenError());
                        dispatch(set_error(true));
                    } else {
                        if (!res.data.data) {
                            dispatch(set_error(res.data.messages));
                        } else {
                            dispatch(set_error(false));
                        }
                    }
                }
            } catch (e) {
                console.log('userAddFetching error: ', e);
            } finally {
                dispatch(fetching_end());
            }
        }
    };

export const userUpdateFetching =
    (props, { username, access_token, data }) =>
    async (dispatch, getState) => {
        const token_error = getState().login.token_error;

        if (!token_error) {
            console.log('userUpdateFetching');

            dispatch(fetching_start());
            try {
                const response = await api.userUpdate.fetch(username, access_token, data, props.i18next);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 64) {
                        dispatch(setTokenError());
                        dispatch(set_error(true));
                    } else {
                        if (!res.data.data) {
                            dispatch(set_error(res.data.messages));
                        } else {
                            dispatch(set_error(false));
                        }
                    }
                }
            } catch (e) {
                console.log('userAddFetching error: ', e);
            } finally {
                dispatch(fetching_end());
            }
        }
    };

export const userGetFetching =
    (props, { username, access_token, user_id }) =>
    async (dispatch, getState) => {
        const token_error = getState().login.token_error;

        if (!token_error) {
            console.log('userGetFetching');

            dispatch(fetching_start());
            try {
                const response = await api.userFetching.fetch(username, access_token, user_id, props.i18next);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 64) {
                        dispatch(setTokenError());
                    } else {
                        if (res.data) {
                            dispatch(user_fetching(res.data.data));
                        }
                    }
                }
            } catch (e) {
                console.log('userGetFetching error: ', e);
            } finally {
                dispatch(fetching_end());
            }
        }
    };

export const usersList = state => state.users.users_list;
export const user = state => state.users.user;
export const loading = state => state.users.loading;
export const filter = state => state.users.filter;
export const error = state => state.users.error;

export default usersSlice.reducer;
