import React, { useState } from 'react';
import Styles from './styles.module.scss';
import { UsersFilter } from '../../components/usersFilter';
import { UsersTable } from '../../components/usersTable';
import { UserDetail } from '../../components/userDetail';

export const UsersPage = () => {
    const [userOpen, setUserOpen] = useState(false);

    return (
        <>
            <section className={Styles.wrapper}>
                <UsersFilter setUserOpen={setUserOpen} />
                <UsersTable setUserOpen={setUserOpen} />
            </section>

            <UserDetail isOpen={userOpen} setIsOpen={setUserOpen} />
        </>
    );
};
