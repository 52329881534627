import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import Styles from './styles.module.scss';
import { Header } from '../header';
import { isAuth } from '../../redux/slices/loginSlice';

export const MainLayout = () => {
    const isAuthState = useSelector(isAuth);

    const wrapperClassName = Styles.wrapper + (!isAuthState ? ' ' + Styles.login : '');

    return (
        <div className={Styles.layout}>
            <Header />
            <div className={wrapperClassName}>
                <Outlet />
            </div>
        </div>
    );
};
