export const book = Object.freeze({
    home: {
        path: '/',
        key: 'MAIN_PAGE',
    },
    applications: {
        path: '/applications',
        key: 'APPLICATIONS',
    },
    reports: {
        path: '/reports',
        key: 'REPORTS',
    },
    users: {
        path: '/users',
        key: 'USERS',
    },
    login: {
        path: '/login',
        key: 'APPLICATIONS',
    },
});
