import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { t } from 'i18next';

import Styles from './styles.module.scss';
import './select.scss';

import { access_token as accessTokenState, token_error as tokenErrorState, username as usernameState, lang as langState } from '../../redux/slices/loginSlice';
import { categoryListFetching, departmentListFetching, filterInitial as filterInitialState, priorityListFetching, signListFetching } from '../../redux/slices/applicationsSlice';

export const ApplicationsFilter = ({ filter, setFilter, setFilterReady }) => {
    const dispatch = useDispatch();
    const access_token = useSelector(accessTokenState);
    const username = useSelector(usernameState);
    const token_error = useSelector(tokenErrorState);
    const filterInitial = useSelector(filterInitialState);
    const [searchLine, setSearchLine] = useState('');
    const lang = useSelector(langState);

    useEffect(() => {
        if (lang) {
            (async () => {
                await dispatch(signListFetching({ access_token, username, lang }));
                await dispatch(categoryListFetching({ access_token, username, lang }));
                await dispatch(departmentListFetching({ lang, access_token, username }));
                await dispatch(priorityListFetching({ lang, access_token, username }));
                setFilterReady(true);
            })();
        } else {
            return;
        }
    }, [token_error, lang]);

    useEffect(() => {
        if (filter) {
            setFilterReady(true);
        }
    }, [filter]);

    const options = [
        { value: '2', label: t('APPLICATIONS_STATUS_NEW') },
        { value: '1', label: t('APPLICATIONS_STATUS_POSTPONED') },
        { value: '3', label: t('APPLICATIONS_STATUS_IN_WORK') },
        { value: '5', label: t('APPLICATIONS_STATUS_COMPLETED') },
        { value: '6', label: t('APPLICATIONS_STATUS_CANCELED') },
        { value: '8', label: t('APPLICATIONS_STATUS_DONE') },
    ];

    //search line
    useEffect(() => {
        const timeOutId = setTimeout(() => {
            setFilter({ ...filter, search: searchLine });
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchLine]);

    return (
        <div className={Styles.wrapper}>
            <div className={Styles.inputTextWrapper}>
                <input type="text" placeholder={t('APPLICATIONS_SEARCH')} onChange={e => setSearchLine(e.target.value)} />
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.33333 14.1667C11.555 14.1667 14.1667 11.555 14.1667 8.33333C14.1667 5.11167 11.555 2.5 8.33333 2.5C5.11167 2.5 2.5 5.11167 2.5 8.33333C2.5 11.555 5.11167 14.1667 8.33333 14.1667Z"
                        stroke="#B3B3BC"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M12.5 12.5L17.5 17.5" stroke="#B3B3BC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>

            <Select
                defaultValue={filter?.status}
                onChange={value => setFilter({ ...filter, status: value })}
                options={options}
                className="select"
                isClearable={true}
                placeholder={t('APPLICATIONS_STATUS')}
                classNamePrefix="filter"
                noOptionsMessage={() => t('NOT_FOUND')}
            />

            <Select
                defaultValue={filter?.sign}
                onChange={value => setFilter({ ...filter, sign: value })}
                options={filterInitial?.sign}
                className="select"
                isClearable={true}
                placeholder={t('APPLICATIONS_SIGNS')}
                classNamePrefix="filter"
                noOptionsMessage={() => t('NOT_FOUND')}
            />

            <Select
                defaultValue={filter?.category}
                onChange={value => setFilter({ ...filter, category: value })}
                options={filterInitial?.category}
                className="select"
                isClearable={true}
                placeholder={t('APPLICATIONS_SOURCE')}
                classNamePrefix="filter"
                noOptionsMessage={() => t('NOT_FOUND')}
            />

            <Select
                defaultValue={filter?.department}
                onChange={value => setFilter({ ...filter, department: value })}
                options={filterInitial?.department}
                className="select"
                isClearable={true}
                placeholder={t('APPLICATIONS_DEPATRMENT')}
                classNamePrefix="filter"
                noOptionsMessage={() => t('NOT_FOUND')}
            />

            <Select
                defaultValue={filter?.priority}
                onChange={value => setFilter({ ...filter, priority: value })}
                options={filterInitial?.priority}
                className="select"
                isClearable={true}
                placeholder={t('APPLICATIONS_PRIORITIES')}
                classNamePrefix="filter"
                noOptionsMessage={() => t('NOT_FOUND')}
            />
        </div>
    );
};
