import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api';
import { setTokenError } from './loginSlice';

export const applicationsSlice = createSlice({
    name: 'applications',
    initialState: {
        tasks_list: [],
        task: null,
        filterInitial: {},
        loading: false,
    },
    reducers: {
        task_list_fetching: (state, action) => {
            state.tasks_list = action.payload;
        },
        task_fetching: (state, action) => {
            state.task = action.payload;
        },
        sign_list_fetching: (state, action) => {
            state.filterInitial = { ...state.filterInitial, sign: action.payload };
        },
        category_list_fetching: (state, action) => {
            state.filterInitial = { ...state.filterInitial, category: action.payload };
        },
        department_list_fetching: (state, action) => {
            state.filterInitial = { ...state.filterInitial, department: action.payload };
        },
        priority_list_fetching: (state, action) => {
            state.filterInitial = { ...state.filterInitial, priority: action.payload };
        },
    },
});

export const { sign_list_fetching, category_list_fetching, department_list_fetching, priority_list_fetching, task_list_fetching, task_fetching } = applicationsSlice.actions;

export const tasksListFetching =
    ({ username, access_token, page, filter, lang }) =>
    async (dispatch, getState) => {
        const token_error = getState().login.token_error;

        if (!token_error) {
            // console.log('tasksListFetching');

            try {
                const response = await api.tasksListFetching.fetch(username, access_token, page, filter, lang);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 64) {
                        dispatch(setTokenError());
                    } else {
                        if (res.data) {
                            dispatch(task_list_fetching(res.data));
                        }
                    }
                }
            } catch (e) {
                console.log('tasksListFetching error: ', e);
            }
        }
    };

export const taskDetailFetching =
    ({ username, access_token, task_id, lang }) =>
    async (dispatch, getState) => {
        const token_error = getState().login.token_error;

        if (!token_error) {
            console.log('taskDetailFetching');

            try {
                const response = await api.taskDetailFetching.fetch(username, access_token, task_id, lang);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 64) {
                        dispatch(setTokenError());
                    } else {
                        if (res.data && res.data.task) {
                            dispatch(task_fetching(res.data.task));
                        }
                    }
                }
            } catch (e) {
                console.log('taskDetailFetching error: ', e);
            }
        }
    };

export const signListFetching =
    ({ access_token, username, lang }) =>
    async (dispatch, getState) => {
        const token_error = getState().login.token_error;

        if (!token_error) {
            // console.log('signListFetching');

            try {
                const response = await api.signListFetching.fetch(username, access_token, lang);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 64) {
                        dispatch(setTokenError());
                    } else {
                        if (res.data) {
                            const data = [];
                            for (const item in res.data) {
                                data.push({
                                    value: item,
                                    label: res.data[item],
                                });
                            }
                            dispatch(sign_list_fetching(data));
                        }
                    }
                }
            } catch (e) {
                console.log('signListFetching error: ', e);
            }
        }
    };

export const categoryListFetching =
    ({ access_token, username, lang }) =>
    async (dispatch, getState) => {
        const token_error = getState().login.token_error;

        if (!token_error) {
            // console.log('categoryListFetching');

            try {
                const response = await api.categoryListFetching.fetch(username, access_token, lang);

                if (response.status === 200) {
                    const res = await response.json();
                    if (res.code === 64) {
                        dispatch(setTokenError());
                    } else {
                        if (res.data) {
                            const data = [];
                            for (const item in res.data) {
                                data.push({
                                    value: item,
                                    label: res.data[item],
                                });
                            }
                            dispatch(category_list_fetching(data));
                        }
                    }
                }
            } catch (e) {
                console.log('categoryListFetching error: ', e);
            }
        }
    };

export const departmentListFetching =
    ({ access_token, username, lang }) =>
    async (dispatch, getState) => {
        const token_error = getState().login.token_error;

        if (!token_error) {
            // console.log('departmentListFetching');

            try {
                const response = await api.departmentListFetching.fetch(username, access_token, lang);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 64) {
                        dispatch(setTokenError());
                    } else {
                        if (res.data) {
                            const data = [];
                            for (const item in res.data) {
                                data.push({
                                    value: item,
                                    label: res.data[item],
                                });
                            }
                            dispatch(department_list_fetching(data));
                        }
                    }
                }
            } catch (e) {
                console.log('departmentListFetching error: ', e);
            }
        }
    };

export const priorityListFetching =
    ({ access_token, username, lang }) =>
    async (dispatch, getState) => {
        const token_error = getState().login.token_error;

        // let ttt = +localStorage.getItem('ttt') || 1;
        // ttt++
        // localStorage.setItem('ttt', ttt);

        if (!token_error) {
            // console.log('priorityListFetching');
            try {
                // console.log('ttt', ttt);
                // if (ttt === 18) {
                //     access_token = '123123';
                // }
                const response = await api.priorityListFetching.fetch(username, access_token, lang);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 64) {
                        dispatch(setTokenError());
                    } else {
                        if (res.data) {
                            const data = [];
                            for (const item of res.data) {
                                data.push({
                                    value: item.id,
                                    label: item.name,
                                });
                            }
                            dispatch(priority_list_fetching(data));
                        }
                    }
                }
            } catch (e) {
                console.log('priorityListFetching error: ', e);
            }
        }
    };

export const filterInitial = state => state.applications.filterInitial;
export const tasksList = state => state.applications.tasks_list;
export const task = state => state.applications.task;

export default applicationsSlice.reducer;
