import React from 'react';
import { useSelector } from 'react-redux';

import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { LeftColumn } from '../components/leftColumn';
import { RightColumn } from '../components/rightColumn';

import { isAuth } from '../redux/slices/loginSlice';

export const RequireAuth = () => {
    const isAuthState = useSelector(isAuth);
    const location = useLocation();

    if (!isAuthState) {
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return (
        <>
            <LeftColumn />
            <RightColumn children={<Outlet />} />
        </>
    );
};
