import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Routes, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import 'modern-normalize/modern-normalize.css';
import './source/styles/default.scss';

import { book } from './navigation/book';
import { MainLayout } from './components/mainLayout/MainLayout';
import { RequireAuth } from './hoc/RequireAuth';
import { LoginPage } from './pages/loginPage';
import { ApplicationsPage } from './pages/applicationsPage';
import { ReportsPage } from './pages/reportsPage';
import { UsersPage } from './pages/usersPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { useRefreshToken } from './hook/useRefreshToken';
import { username as userNameState, access_token as accessTokenState, checkAuth, getLanguage, loading, token_error as tokenErrorState, lang as langState } from './redux/slices/loginSlice';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';

export const App = () => {
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies();

    const loginLoading = useSelector(loading);
    const access_token = useSelector(accessTokenState);
    const token_error = useSelector(tokenErrorState);
    const lang = useSelector(langState);
    const username = useSelector(userNameState);

    const refreshTokenDispatch = useRefreshToken();

    const { ready } = useTranslation();

    useEffect(() => {
        if (token_error) {
            console.log('refreshTokenDispatch');
            refreshTokenDispatch();
        }
    }, [token_error]);

    useEffect(() => {
        dispatch(checkAuth(cookies));

        if (cookies.i18next) {
            return;
        } else {
            setCookie('i18next', 'en', { path: '/' });
        }
    }, [dispatch]);

    useEffect(() => {
        setCookie('access_token', access_token);
        (async () => await dispatch(getLanguage(username, access_token)))();
    }, [access_token]);

    const setLng = async lng => {
        if (lng) {
            await i18n.changeLanguage(lng);
            setCookie('i18next', lng, { path: '/' });
        } else {
            return;
        }
    };

    useEffect(() => {
        if (lang) {
            setCookie('i18next', lang, { path: '/' });
            setLng(lang);
        } else if (cookies.i18next) {
            setCookie('i18next', cookies.i18next, { path: '/' });
        } else {
            setCookie('i18next', 'en', { path: '/' });
        }
    }, [lang]);

    if (loginLoading) {
        return false;
    }

    if (ready) {
        return (
            <>
                <Routes>
                    <Route element={<MainLayout />}>
                        <Route element={<RequireAuth />}>
                            <Route path="/" element={<Navigate to={book.applications.path} />} />
                            <Route path={book.applications.path} element={<ApplicationsPage />} />
                            <Route path={book.reports.path} element={<ReportsPage />} />
                            <Route path={book.users.path} element={<UsersPage />} />
                        </Route>
                        <Route path={book.login.path} element={<LoginPage />} />
                        <Route path="*" element={<NotFoundPage />} />
                    </Route>
                </Routes>
            </>
        );
    }
    return <span>Loading...</span>;
};
