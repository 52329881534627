import React from 'react';
import { TitleRow } from '../titleRow';
import Styles from './styles.module.scss';

export const RightColumn = ({ children }) => {
    return (
        <section className={Styles.section}>
            <TitleRow />
            <section className={Styles.content}>{children}</section>
        </section>
    );
};
