import React, { useEffect, useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import Select from 'react-select';
import { Field, Form, Formik, useField } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';

import 'react-sliding-pane/dist/react-sliding-pane.css';
import Styles from './styles.module.scss';
import './select.scss';
import closeIcon from './images/close-square.svg';
import { t } from 'i18next';

import {
    error as errorState,
    filter as filterState,
    loading as loadingUser,
    set_error,
    user as userState,
    user_fetching,
    userAddFetching,
    usersListFetching,
    userUpdateFetching,
} from '../../redux/slices/usersSlice';
import { access_token as accessTokenState, token_error as tokenErrorState, username as usernameState } from '../../redux/slices/loginSlice';

export const UserDetail = ({ isOpen, setIsOpen }) => {
    const dispatch = useDispatch();
    const access_token = useSelector(accessTokenState);
    const username = useSelector(usernameState);
    const loading = useSelector(loadingUser);
    const filter = useSelector(filterState);
    const user = useSelector(userState);
    const error = useSelector(errorState);
    const token_error = useSelector(tokenErrorState);

    const [formData, setFormData] = useState(null);
    const [formSubmit, setFormSubmit] = useState(false);
    const [cookies] = useCookies();

    const closeHandler = () => {
        setIsOpen(false);
        dispatch(user_fetching(null));
        dispatch(set_error(false));
    };

    const options = [
        { value: '1', label: t('USERS_USER_STATUS_ACTIVE') },
        { value: '0', label: t('USERS_USER_STATUS_FIRED') },
    ];

    const CustomSelectField = ({ options, ...props }) => {
        const [field, , helpers] = useField(props);
        const { setValue } = helpers;

        const selectHandler = v => {
            setValue(v.value);
        };

        return (
            <Select
                defaultValue={options.find(item => item.value === field.value)}
                onChange={val => selectHandler(val)}
                options={options}
                className="customSelect"
                classNamePrefix="customSelect"
                isClearable={false}
                isSearchable={false}
            />
        );
    };

    const CustomPhoneField = props => {
        const [field, , helpers] = useField(props);
        const { setValue } = helpers;

        useEffect(() => {
            const val = field.value;

            if (val.length > 0) {
                const formattedVal = val.substring(0, 1).replace(/[^\d+]/g, '') + val.slice(1).replace(/\D/g, '');
                setValue(formattedVal);
            }
        }, [field.value]);

        return <Field {...props} />;
    };

    let userSchema = '';

    const formState = {
        name: '',
        phone: '',
        password: '',
        status: '1',
    };

    if (user && user.id) {
        if (user.contractor_name) {
            formState.name = user.contractor_name;
        }
        if (!user.status) {
            formState.status = '0';
        }
        if (user.phone) {
            formState.phone = user.phone;
        }

        userSchema = Yup.object().shape({
            name: Yup.string().min(2, t('USERS_NOTIF_NAME_MIN')).max(100, t('USERS_NOTIF_NAME_MAX')).required(t('USERS_NOTIF_REQUIRED')),
            password: Yup.string().min(8, t('USERS_NOTIF_PASS_MIN')).max(50, t('USERS_NOTIF_PASS_MAX')),
            phone: Yup.string().min(6, t('USERS_NOTIF_TEL_MIN')).max(50, t('USERS_NOTIF_TEL_MAX')).required(t('USERS_NOTIF_REQUIRED')),
        });
    } else {
        userSchema = Yup.object().shape({
            name: Yup.string().min(2, t('USERS_NOTIF_NAME_MIN')).max(100, t('USERS_NOTIF_NAME_MAX')).required(t('USERS_NOTIF_REQUIRED')),
            password: Yup.string().min(8, t('USERS_NOTIF_PASS_MIN')).max(50, t('USERS_NOTIF_PASS_MAX')).required(t('USERS_NOTIF_REQUIRED')),
            phone: Yup.string().min(6, t('USERS_NOTIF_TEL_MIN')).max(50, t('USERS_NOTIF_TEL_MAX')).required(t('USERS_NOTIF_REQUIRED')),
        });
    }

    useEffect(() => {
        (async () => {
            if (formData) {
                if (user && user.id) {
                    //обновление пользователя
                    await dispatch(userUpdateFetching(cookies, { username, access_token, data: { ...formData, id: user.id } }));
                } else {
                    //создание пользователя
                    await dispatch(userAddFetching(cookies, { username, access_token, data: formData }));
                }

                setFormSubmit(true);
            }
        })();
    }, [formData, token_error, cookies.i18next]);

    useEffect(() => {
        (async () => {
            if (formSubmit) {
                setFormSubmit(false);
                if (!error) {
                    closeHandler();
                    await dispatch(usersListFetching(cookies, { username, access_token, filter }));
                }
                setFormData(null);
            }
        })();
    }, [formSubmit, cookies.i18next]);

    return (
        <SlidingPane overlayClassName={Styles.overlay} className={Styles.panel} isOpen={isOpen} hideHeader={true} width="350px" onRequestClose={closeHandler}>
            <div className={Styles.header}>
                <div className={Styles.title}>{user?.id ? `${t('USERS_USER_ID')} ${user.id}` : `${t('USERS_NEW_USER')}`}</div>
                <button className={Styles.closeIcon} onClick={closeHandler}>
                    <img src={closeIcon} alt="close" />
                </button>
            </div>

            <div className={Styles.content}>
                <Formik initialValues={formState} onSubmit={setFormData} validationSchema={userSchema}>
                    {({ errors, touched }) => (
                        <Form className={Styles.form}>
                            <label>
                                <p>{t('USERS_FULL_NAME_SHORT')}</p>
                                <Field name="name" type="text" />
                                {isOpen && errors.name && touched.name ? <div className={Styles.fieldError}>{errors.name}</div> : null}
                            </label>
                            <label>
                                <p>{t('USERS_TEL')}</p>
                                <CustomPhoneField name="phone" type="text" />
                                {isOpen && errors.phone && touched.phone ? <div className={Styles.fieldError}>{errors.phone}</div> : null}
                                {isOpen && error?.phone && !errors.phone && touched.phone ? <div className={Styles.fieldError}>{t('USERS_NOTIF_EXISTS')}</div> : null}
                            </label>
                            <label>
                                <p>{t('USERS_PASSWORD')}</p>
                                <Field name="password" type="text" />
                                {isOpen && errors.password && touched.password ? <div className={Styles.fieldError}>{errors.password}</div> : null}
                            </label>
                            <label>
                                <p>{t('USERS_STATUS')}</p>
                                <CustomSelectField name="status" options={options} />
                            </label>

                            <button type="submit" disabled={!!loading} className={`btn ${Styles.btn}`}>
                                {t('USERS_SAVE_BTN')}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </SlidingPane>
    );
};
